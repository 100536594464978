import Message from "./message";
function plugins(Vue) {
    Vue.prototype.$message = function (message, callback) {
        // 继承Message组件
        const MessageConstructor = Vue.extend(Message);
        // 创建一个新元素
        const messageWrap = document.createElement('div');
        // 实例化Message组件，并将组件挂在到新创建的messageWrap元素上
        const messageInstace = new MessageConstructor({
            el: messageWrap
        });
        document.body.appendChild(messageInstace.$el);
        // 显示组件内容
        if (message) {
            messageInstace.text = message;
        }
        if (typeof callback === "function") {
            setTimeout(() => {
                callback();
            }, 1500);
        }
        messageInstace.show = true;
    };
}

export default plugins;
