(function (doc, win) {
    let baseSize = 100;
    let docEl = doc.documentElement,
        resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
        recalc = function () {
            if (docEl.clientWidth <= 1200) {
                const scale = docEl.clientWidth / 375;
                docEl.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px';
            }
            doc.getElementById("app").style.width = "auto";
        };
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener("DOMContentLoaded", recalc, false);
})(document, window);
