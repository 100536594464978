import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/iconfont/iconfont.css"
import "@/assets/css/common.css"
import '@/utils/rem.js';
Vue.config.productionTip = false

import plugins from "@/components/index.js";

Vue.use(plugins);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
