<template>
  <transition name='fade'>
    <div class="message" v-if="show">
      {{ text }}
    </div>
  </transition>
</template>

<script>
export default {
  name: "message",
  data() {
    return {
      show: false
    };
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  watch: {
    // 自动关闭
    show(val) {
      if (val) {
        setTimeout(() => {
          this.show = false;
        }, 1500);
      }
    }
  },
  created() {
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.message {
  position: fixed;
  z-index: 10000;
  top: 48%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background: #000000;
  border-radius: 4px;
  opacity: 0.75;
  font-size: 15px;
  color: #FFFFFF;
  text-align: center;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  width: fit-content;
}
.fade-enter {
  opacity: 0;
  transform: translate(-50%, 30px);
}
.fade-enter-active {
  transition: all .5s;
}
.fade-enter-to {
  opacity: 1;
  transform: translate(-50%, 0);
}
.fade-leave {
  opacity: 1;
  transform: translate(-50%, 0);
}
.fade-leave-active {
  transition: all .5s;
}
.fade-leave-to {
  opacity: 0;
  transform: translate(-50%, 30px);
}
@media (min-width: 750px) and (max-width: 960px) {
}
@media (min-width: 961px) {
  .message {
    top: 48%;
    padding: 14px 16px;
    font-size: 16px;
    border-radius: 4px;
    opacity: 0.75;
  }
}
</style>
